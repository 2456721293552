@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

html {
  height: 100%;
}

body {
  overflow-x: hidden;
  height: 100%;
  padding: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.offcanvas-header > .btn-close {
  align-self: start !important;
}

.fb-post,
.fb-post iframe[style],
.fb-post span,
.fb-post.fb_iframe_widget span iframe {
  width: 100% !important;
}
.fb-post {
  cursor: pointer;
}
.fb-post > * {
  pointer-events: none;
}

$rootColor: #f2f4f7;

.adv-bg-inherit {
  background-color: inherit;
}

.adv-bg {
  background: $rootColor;
}

.adv-bg-gray-200 {
  background-color: $gray-200;
}

.adv-color-gray-700 {
  color: $gray-700;
}

.adv-w-20 {
  width: 20% !important;
}

.adv-w-33 {
  width: 33.3333333% !important;
}

.adv-w-70 {
  width: 70% !important;
}

.adv-w-90 {
  width: 90% !important;
}

.adv-h-0_75rem {
  height: 0.75rem;
}

.adv-h-20 {
  height: 20% !important;
}

.adv-min-h-3_25rem {
  min-height: 3.25rem;
}

.adv-z-index-3 {
  z-index: 3 !important;
}

.adv-z-index-4 {
  z-index: 4 !important;
}

.adv-z-index-1046 {
  z-index: 1046 !important;
}

.adv-z-index-6 {
  z-index: 6;
}

.adv-mt-2_5 {
  margin-top: 0.625rem;
}

.adv-me-3_5 {
  margin-right: 1.25rem;
}

.adv-ms-3_5 {
  margin-left: 1.25rem;
}

.adv-pt-4rem {
  padding-top: 4rem;
}

.adv-max-vw-95 {
  max-width: 95vw !important;
}

.adv-min-width-12rem {
  min-width: 12rem !important;
}

.adv-max-width-14rem {
  max-width: 14rem !important;
}
.adv-max-width-24rem {
  max-width: 24rem !important;
}

.adv-top-2 {
  top: 2px !important;
}

.adv-top-6 {
  top: 6px !important;
}

.adv-end-2 {
  right: 2px !important;
}

.adv-top-right-minus-05 {
  top: -0.5em;
  right: -0.5em;
}

.adv-bottom-right-05 {
  bottom: 0.5em;
  right: 0.5em;
}

.adv-font-roboto {
  font-family: 'Roboto', serif;
}

.adv-font-1_125rem {
  font-size: 1.125rem;
}

.adv-font-1_875rem {
  font-size: 1.875rem;
}

.adv-font-0_725em {
  font-size: 0.725em;
}

.adv-font-1_2em {
  font-size: 1.2em;
}

.adv-font-1_3em {
  font-size: 1.3em;
}

.adv-font-1_5em {
  font-size: 1.5em;
}

.adv-font-1_6em {
  font-size: 1.6em;
}

.adv-line-height-133 {
  line-height: 1.33;
}
.adv-line-height-175 {
  line-height: 1.75;
}
.adv-line-height-32 {
  line-height: 32px;
}

.adv-cut-off-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.adv-wrap-anywhere {
  overflow-wrap: anywhere;
}

.adv-max-width-50px {
  max-width: 50px;
}

.adv-pt-100-percent {
  padding-top: 100% !important;
}

.adv-object-fit-scale-down {
  object-fit: scale-down !important;
}

.adv-row-gutter-05rem {
  --bs-gutter-x: 0.5rem !important;
  --bs-gutter-y: 0.5rem !important;
}

.adv-row-gutter-0\.125rem {
  --bs-gutter-x: 0.125rem !important;
  --bs-gutter-y: 0.125rem !important;
}

.react-tel-input {
  .special-label {
    display: block !important;
    position: absolute !important;
    z-index: 1 !important;
    font-family: var(--bs-font-sans-serif);
    font-size: 0.85rem !important;
    left: 0 !important;
    top: 0 !important;
    background: inherit !important;
    padding: 5px 14px !important;
    white-space: nowrap !important;
  }

  .selected-flag .flag {
    margin-top: -3px !important;
  }

  .selected-flag.open:before {
    border-color: #ffffff00 !important;
    box-shadow: none !important;
  }

  .form-control {
    padding: 26px 14px 9px 60px !important;
  }
}

.adv__control {
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2)) !important;
}

.adv-react-select {
  .adv__input {
    opacity: 1 !important;
  }

  .adv__input-container {
    margin: 0 !important;
    padding: 0 !important;
  }

  .adv__placeholder {
    color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }

  .adv__control--is-focused .adv__placeholder,
  .adv__placeholder--has-value {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }

  .adv__control--is-focused .adv__value-container,
  .adv__value-container--has-value {
    margin-top: 1.8rem !important;
    margin-left: 0.2rem;
    margin-bottom: 0.2rem;
  }
}

.adv-divider {
  height: 1px;
  margin: 1.5rem auto;
  background: $gray-300;
}

.adv-btn-no-hover.btn:hover,
.adv-btn-no-hover.btn-check:checked + .btn,
.adv-btn-no-hover.btn.active,
.adv-btn-no-hover.btn.show,
.adv-btn-no-hover.btn:first-child:active,
.adv-btn-no-hover:not(.btn-check) + .btn:active {
  color: inherit;
  background-color: inherit;
  border-color: transparent;
}

.adv-no-shaking {
  transform: translateZ(0);
}

.adv-link {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
  cursor: pointer;
}

.adv-show-accordion-item-top-border.accordion-item:not(:first-of-type) {
  border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.adv-rotate-180 {
  transform: rotate(180deg);
}

.adv-duration-150 {
  transition: 0.15s;
}

.adv-menu-icon {
  color: #212529;
  cursor: pointer;

  &:hover,
  &.active {
    color: #0d6efd;
  }
}

.adv-cursor {
  display: inline-block;
  background-color: currentcolor;
  margin-left: 0.1rem;
  width: 2px;
  animation: cursor-blink 1s infinite;
}
.adv-cursor.typing {
  animation: none;
}

.adv-card {
  border: 1px solid #9ec5fe;
  border-radius: 10px;

  &:hover {
    border: 1px solid #6ea8fe;
  }

  &.active {
    border: 2px solid #0d6efd;
  }

  &.disabled {
    opacity: 0.5;
  }
}

@keyframes cursor-blink {
  0% {
    background-color: currentcolor;
  }
  49% {
    background-color: currentcolor;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: currentcolor;
  }
}
