$font-weight-light: 300 !default;
$font-weight-base: $font-weight-light !default;

$offcanvas-horizontal-width: 200px !default;

$grid-breakpoints: (
  xs: 0,
  sm: 350px,
  md: 576px,
  lg: 768px,
  xl: 992px,
  xxl: 1200px
) !default;

@media (min-width: map-get($grid-breakpoints, sm)) {
  body .offcanvas {
    right: 0 !important;
  }
  #root .toast-container {
    right: 0 !important;
  }
}

@media (min-width: map-get($grid-breakpoints, md)) {
  body .offcanvas {
    right: 16.66666667% !important;
  }
  #root .toast-container {
    right: 16.66666667% !important;
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) {
  body .offcanvas {
    right: 25% !important;
  }
  #root .toast-container {
    right: 25% !important;
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  body .offcanvas {
    right: 25% !important;
  }
  #root .toast-container {
    right: 25% !important;
  }
}

@media (min-width: map-get($grid-breakpoints, xxl)) {
  body .offcanvas {
    right: 33.33333333% !important;
  }
  #root .toast-container {
    right: 33.33333333% !important;
  }
}

$focus-ring-width: 0rem !default;

@import '~bootstrap/scss/bootstrap';

.toast {
  --bs-toast-spacing: 0.5rem !important;
}

.form-floating > label {
  z-index: inherit !important;
}

.form-floating > .form-control:focus {
  &::placeholder {
    color: $input-placeholder-color !important;
  }
}

.form-floating > :disabled ~ label::after {
  background-color: $gray-200 !important;
}

.form-control.is-invalid {
  background-image: none !important;
}

.badge {
  transition: 0.15s;
}

.input-group-text.disabled {
  background: var(--bs-secondary-bg);
}

.accordion-button:not(.collapsed) {
  color: inherit !important;
  background-color: inherit !important;
  box-shadow: inherit !important;
}

.modal-body {
  word-break: break-word;
}

.adv-tooltip-lg .tooltip-inner {
  max-width: 24rem;
}

.adv-tooltip-opacity-1.tooltip {
  --bs-tooltip-opacity: 1;
}
